import type { JSX } from 'preact';
import classNames from 'classnames';

export default function Star(props: { full: boolean }): JSX.Element {
  const { full } = props;

  return (
    <div
      className={classNames('page-album-grading__star', {
        'page-album-grading__star--full': full,
      })}
    ></div>
  );
}
