import smoothscroll from 'smoothscroll-polyfill';
import '@hotwired/turbo-rails';
import { start, ui } from '@nerdgeschoss/shimmer';
import { application } from '../controllers/application';
import '../controllers';
import '../sprinkles/preflight';
import { startErrorTracking } from '../lib/errortracking';

declare global {
  interface Window {
    gtag(...arg: unknown[]): void;
  }
}

async function startAnalytics(): Promise<void> {
  window.gtag('consent', 'default', {
    ad_storage: 'denied',
    ad_user_data: 'denied',
    ad_personalization: 'denied',
    analytics_storage: 'denied',
  });
  await ui.consent.enableGoogleTagManager('GTM-5BJPKT3V');
  window.gtag('consent', 'update', {
    ad_storage: 'granted',
    ad_user_data: 'granted',
    ad_personalization: 'granted',
    analytics_storage: 'granted',
  });
}

start({ application });
smoothscroll.polyfill();
startErrorTracking();
startAnalytics();
