import { Controller } from '@hotwired/stimulus';
import { type JSX, h, render } from 'preact';

import AlbumPanelContent from '../components/AlbumPanelContent';

const components = {
  AlbumPanelContent,
} as Record<string, (props: unknown) => JSX.Element>;

export default class PreactController extends Controller {
  static targets = [];
  static values = {
    component: String,
    props: Object,
  };
  declare componentValue: string;
  declare propsValue: Record<string, unknown>;

  async connect(): Promise<void> {
    const Component = components[this.componentValue];
    if (!Component) {
      throw new Error(`Could not find component "${this.componentValue}"`);
    }

    render(h(Component, this.propsValue, null), this.element);
  }

  disconnect(): void {
    // Rendering `null` properly unmounts the element. Via https://stackoverflow.com/a/54853028/6028913
    render(null, this.element);
  }
}
