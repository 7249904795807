import { useEffect, useState } from 'preact/hooks';

export function useMediaQuery(query: string): boolean {
  const matchMedia = window.matchMedia(query);
  const [matches, setMatches] = useState(matchMedia.matches);

  function handleChange(event: MediaQueryListEvent): void {
    setMatches(event.matches);
  }

  useEffect(() => {
    matchMedia.addEventListener('change', handleChange);

    return () => {
      matchMedia.removeEventListener('change', handleChange);
    };
  });

  return matches;
}
