import type { JSX } from 'preact';
import Item from './Item';
import type { AlbumI18nClient } from './AlbumPanelContent';
import classNames from 'classnames';

interface Item {
  id: number;
  description: string | null;
  grading: number;
  gradingLabel: string;
  priceHtml: string;
}

export default function SelectItem(props: {
  item: Item;
  i18n: AlbumI18nClient;
  href: {
    shippingPath: string;
    gradingsPath: string;
  };
  onClick: () => void;
  isSelected: boolean;
}): JSX.Element {
  const { item, i18n, href, onClick, isSelected } = props;

  return (
    <button
      type="button"
      className={classNames('condition-table__row condition-item', {
        'condition-item--selected': isSelected,
      })}
      onClick={(e) => {
        const target = e.target as HTMLElement;

        // the tooltip opens on click on touch devices, so we don't want to
        // execute the onClick function in this case
        target.classList.contains('page-album-tooltip') ? '' : onClick();
      }}
    >
      <Item item={item} i18n={i18n} href={href} isMainItem={false} />
    </button>
  );
}
