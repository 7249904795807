import * as Sentry from '@sentry/browser';

export function startErrorTracking(): void {
  enrichTurboErrors();

  const sentryEnv = process.env.SENTRY_ENV || process.env.RAILS_ENV;
  if (!sentryEnv || ['development', 'test'].includes(sentryEnv)) return;

  Sentry.init({
    dsn: 'https://60e405d6f34540c1a40035c9fc6a3bd8@o385233.ingest.sentry.io/5660183',
    environment: sentryEnv,
    allowUrls: [/recordsale\.de/, /herokuapp\.com/],
    ignoreErrors: [/TurboError/],
  });
}

export function trackError(
  error: Error,
  extra: Record<string, string> = {}
): void {
  Sentry.captureException(error, { extra });
}

function enrichTurboErrors(): void {
  document.addEventListener('turbo:fetch-request-error', ((
    event: CustomEvent<{ request: Request; error: TypeError }>
  ) => {
    const { error, request } = event.detail;

    // By default, Turbo throws fetch errors, but by default, they don't include any information, e.g. the request URL.
    // Luckily, Turbo will throw this exact error instance when we return from this function,
    // so we can add more information.
    error.message = `Unable to fetch ${request.url}. Original Error: ${error.name}: ${error.message}`;
    error.name = 'TurboError';
  }) as EventListener);
}
