export class Waiter {
  private resolve: ((value: unknown) => void) | null = null;
  readonly promise = new Promise((res) => {
    this.resolve = res;
  });

  finish: () => void = () => {
    this.resolve?.(null);
    this.resolve = null;
  };
}
