import { Controller } from '@hotwired/stimulus';
import { Waiter } from '../model/waiter';

const waiter = new Waiter();
Object.assign(window, { recaptchaCallback: waiter.finish });

declare global {
  interface Window {
    grecaptcha: {
      render: (element: Element, params: Record<string, string>) => void;
    };
  }
}

export default class extends Controller {
  async connect(): Promise<void> {
    await waiter.promise;
    window.grecaptcha.render(this.element, {
      sitekey: (this.element as HTMLDivElement).dataset.sitekey ?? '',
    });
  }
}
