import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="record-tabs"
export default class extends Controller {
  static targets = ['tabItem', 'tabContent'];

  declare tabItemTargets: HTMLElement[];
  declare tabContentTargets: HTMLDivElement[];

  connect(): void {
    // set active class to first tab item
    this.tabItemTargets[0].classList.add('page-album-tabs__item--active');
    this.tabItemTargets[0].classList.add('text-next--color-tab-label-active');

    // set active class to first tab content
    this.tabContentTargets[0].classList.add('page-album-tabs__content--active');
  }

  show(event: Event): void {
    const element = event.currentTarget as HTMLElement;

    // remove active class from all tab items
    this.tabItemTargets.forEach((tabItem) => {
      tabItem.classList.remove('page-album-tabs__item--active');
      tabItem.classList.remove('text-next--color-tab-label-active');
    });

    // remove active class from all tab contents
    this.tabContentTargets.forEach((tabContent) => {
      tabContent.classList.remove('page-album-tabs__content--active');
    });

    // add active class to clicked tab item
    element.classList.add('page-album-tabs__item--active');
    element.classList.add('page-album-tabs__content--active');

    // add active class to corresponding tab content
    const tabContent = this.tabContentTargets.find(
      (content) => content.dataset.content === element.dataset.item
    );

    tabContent?.classList.add('page-album-tabs__content--active');
  }
}
