import { Controller } from '@hotwired/stimulus';
import { ui } from '@nerdgeschoss/shimmer';

export default class extends Controller {
  show(event: MouseEvent): void {
    event.preventDefault();
    ui.popover.open({
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      url: this.data.get('path')!,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      selector: this.element as any,
    });
  }
}
