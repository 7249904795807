import type { JSX } from 'preact';
import Grading from './Grading';
import type { AlbumI18nClient } from './AlbumPanelContent';
import classNames from 'classnames';

interface Item {
  id: number;
  description: string | null;
  grading: number;
  gradingLabel: string;
  priceHtml: string;
}

export default function Item(props: {
  item: Item;
  i18n: AlbumI18nClient;
  href: {
    shippingPath: string;
    gradingsPath: string;
  };
  isMainItem: boolean;
}): JSX.Element {
  const { item, i18n, href, isMainItem } = props;

  return (
    <div className="condition-table__row-wrapper stack stack--tiny stack--tablet-row-gap-0">
      <header
        className={classNames('condition-table__header', {
          'condition-table__header--item': !isMainItem,
        })}
      >
        <div className="condition-table__cell">
          <p className="text-next text-next--dropdown-album-caption text-next--color-label-caption-base">
            {i18n.t('condition')}
          </p>
        </div>
        <div className="condition-table__cell stack stack--small stack--flex">
          <p className="text-next text-next--dropdown-album-caption text-next--color-label-caption-base">
            {i18n.t('coverAndMediaGrading')}
          </p>
          <a
            href={href.gradingsPath}
            className="page-album-tooltip page-album-tooltip--small"
            title={i18n.t('gradingInformation')}
            rel="nofollow"
            target="_blank"
          ></a>
        </div>
        <div className="condition-table__cell stack stack--small stack--flex">
          <p className="text-next text-next--dropdown-album-caption text-next--color-label-caption-base">
            {i18n.t('price')}
          </p>
          <div className="page-album-tooltip page-album-tooltip--small page-album-tooltip--price">
            <div className="page-album-tooltip__content">
              <div className="text-next text-next--paragraph-primary-regular">
                {i18n.t('inclVatPlus')}&ensp;
                <a
                  class="text-next text-next--link-default text-next--color-button-label-secondary-default"
                  href={href.shippingPath}
                  rel="nofollow"
                  target="_blank"
                >
                  {i18n.t('shippingCosts')}
                </a>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="condition-table__content">
        <div className="condition-table__cell">
          <Grading grading={item.grading} />
        </div>
        <div
          className={classNames(
            'condition-table__cell text-next text-next--dropdown-album-label-secondary',
            { 'text-next--weight-medium': isMainItem }
          )}
        >
          {item.gradingLabel}
        </div>
        <div
          className={classNames(
            'condition-table__cell stack stack--flex text-next text-next--dropdown-album-label-secondary',
            { 'text-next--weight-medium': isMainItem }
          )}
          dangerouslySetInnerHTML={{ __html: item.priceHtml }}
        ></div>
      </div>
    </div>
  );
}
