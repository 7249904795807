import { Controller } from '@hotwired/stimulus';
import { wait } from '../model/timing';

// Connects to data-controller="album-explorer"
export default class extends Controller {
  static targets = ['scroll', 'detail'];
  static values = { feature: Boolean };
  declare featureValue: boolean;
  declare scrollTarget: HTMLDivElement;
  declare detailTarget: HTMLDivElement;
  #lastElement?: HTMLElement;

  connect(): void {
    if (this.featureValue) {
      this.explore();
    }
  }

  explore(): void {
    this.element.classList.add('exploring');
  }

  stopExploring(): void {
    this.element.classList.remove('exploring');
  }

  scrollLeft(): void {
    this.scrollTarget.scrollBy({ left: -500, behavior: 'smooth' });
  }

  scrollRight(): void {
    this.scrollTarget.scrollBy({ left: 500, behavior: 'smooth' });
  }

  async showDetails(event: Event): Promise<void> {
    const element = event.currentTarget as HTMLImageElement;
    const data = element.dataset;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any).gtag('event', 'explore_cover_clicked', {
      name: data['name'],
      artist: data['artist'],
    });
    this.detailTarget.style.backgroundImage = `url('${data['thumb'] ?? ''}')`;
    this.#detailElement('.album_data').style.opacity = '1';
    this.#detailElement('.album_detail__inner').style.backgroundImage = `url('${
      data['image'] ?? ''
    }')`;
    this.#detailElement('.album_artist_name').innerText = data['artist'] ?? '';
    this.#detailElement('.album_price').innerText = data['price'] ?? '';
    this.#detailElement('.album_name').innerText = data['name'] ?? '';
    this.#detailElement('.album_year').innerText = data['year'] ?? '';
    (this.#detailElement('.album_path') as HTMLAnchorElement).href =
      data['path'] ?? '';

    this.#lastElement = element;
    this.detailTarget.style.transform = this.#transformFor(element);
    this.detailTarget.classList.remove('hidden');
    await wait(0);
    this.detailTarget.style.transform = 'none';
  }

  async hideDetails(): Promise<void> {
    const element = this.#lastElement;
    if (!element) return;
    this.detailTarget.style.transform = this.#transformFor(element);
    this.#detailElement('.album_data').style.opacity = '0';
    await wait(0.8);
    this.detailTarget.classList.add('hidden');
  }

  #detailElement(selector: string): HTMLElement {
    return this.detailTarget.querySelector(selector) as HTMLDivElement;
  }

  #transformFor(element: HTMLElement): string {
    const scale = 100.0 / 450;
    const offsetTop = -200 + element.offsetTop;
    const offsetLeft =
      window.innerWidth * -0.5 +
      225 -
      176 +
      element.offsetLeft -
      (element.parentElement?.parentElement?.scrollLeft ?? 0);
    return `translateX(${offsetLeft}px) translateY(${offsetTop}px) scale(${scale})`;
  }
}
