let applePaySupported: boolean | undefined;
let googlePaySupported: boolean | undefined;

export async function supportsApplePay(): Promise<boolean> {
  if (applePaySupported !== undefined) {
    return applePaySupported;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const session = (window as any).ApplePaySession;
  const supported = session?.canMakePayments() ?? false;
  applePaySupported = supported;
  return supported;
}

export async function supportsGooglePay(): Promise<boolean> {
  if (googlePaySupported !== undefined) {
    return googlePaySupported;
  }
  if (!window.PaymentRequest) {
    return false;
  }
  const request = new PaymentRequest(
    [
      {
        supportedMethods: `https://google.com/pay`,
      },
    ],
    {
      total: { amount: { currency: 'EUR', value: '10' }, label: 'total' },
    }
  );
  const supported = await request.canMakePayment();
  googlePaySupported = supported;
  return supported;
}
