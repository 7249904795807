import type { JSX } from 'preact';
import Star from './Star';

export default function Grading(props: { grading: number }): JSX.Element {
  const { grading } = props;

  return (
    <div className="page-album-grading">
      {[1, 2, 3, 4, 5].map((item) => (
        <Star full={grading >= item} />
      ))}
    </div>
  );
}
