import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button', 'slide'];
  declare buttonTargets: HTMLDivElement[];
  declare slideTargets: HTMLDivElement[];

  #index = 0;

  hover(event: Event): void {
    const button = event.currentTarget as HTMLDivElement;
    const index = this.buttonTargets.indexOf(button);
    this.#moveToIndex(index);
  }

  #moveToIndex(index: number): void {
    if (this.#index === index) {
      return;
    }
    this.#index = index;
    this.buttonTargets.forEach((button, i) => {
      button.classList.toggle('fader_controls__handle--active', i === index);
    });
    this.slideTargets.forEach((slide, i) => {
      slide.classList.toggle('fader__slide--displayed', i === index);
      slide.classList.toggle('fader__slide--hidden', i !== index);
    });
  }
}
