import { Controller } from '@hotwired/stimulus';

export default class RecordsTableController extends Controller {
  static targets = [];

  toggleRow(event: MouseEvent): void {
    if (!event.currentTarget) return;

    const row = event.currentTarget as HTMLTableRowElement;
    const labelLink = row.querySelector('a[href]');

    // don't toggle row if user clicked on the label link
    if (event.target === labelLink) return;

    row.classList.toggle('page-album-table__row--expanded');
  }
}
