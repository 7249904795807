import type { JSX } from 'preact';
import Item from './Item';
import type { AlbumI18nClient } from './AlbumPanelContent';
import classNames from 'classnames';

interface Item {
  id: number;
  description: string | null;
  grading: number;
  gradingLabel: string;
  priceHtml: string;
}

export default function MainItem(props: {
  item: Item;
  i18n: AlbumI18nClient;
  href: {
    shippingPath: string;
    gradingsPath: string;
  };
  toggleOpen: () => void;
  otherItemsCount: number;
  isOpen: boolean;
}): JSX.Element {
  const { i18n, isOpen } = props;

  return (
    <div className="condition-table__row condition-table__row--main stack stack--regular stack--tablet-flex">
      <Item {...props} isMainItem />
      {props.otherItemsCount > 0 && (
        <button
          className={classNames(
            'page-album-button page-album-button--secondary page-album-button--small page-album-button--arrow',
            { 'page-album-button--arrow--open': isOpen }
          )}
          type="button"
          onClick={props.toggleOpen}
        >
          <span className="text-next text-next--button-default text-next--color-button-label-secondary-default text-next--tablet-font-size-0">
            {i18n.t('chooseOtherCondition')}
          </span>
        </button>
      )}
    </div>
  );
}
